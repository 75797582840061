import React from 'react'
import loadable from '@loadable/component'
import Cookies from '../components/cookies/cookies'
import Layout from '../layouts/index'
import { termsPageContent, } from '../utils/informations'

const SecondaryPagesHeader = loadable(() => import('../components/secondary-pages-header/secondary-pages-header'))



const TermsConditions = () => {

  return (
    <Layout pageTitle="Terms and Conditions">
      <SecondaryPagesHeader title={'Terms and Conditions'} />
        <Cookies props={termsPageContent}/>
    </Layout>
  )

}

export default TermsConditions