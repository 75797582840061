import React, { useState, useEffect } from 'react'
import './cookies.scss'
import { MyTheme } from '../../utils/informations'

//import 'uikit/dist/css/uikit.css'


const Cookies = ({ props, cookie, type }) => {

    const fetchData = type == "privacy" ? process.env.GATSBY_LANDING_PRIVACY_TEMPLATE : type == "cookie" ? process.env.GATSBY_LANDING_COOKIE_TEMPLATE : process.env.GATSBY_LANDING_TERMS_TEMPLATE

    const [cookieText, setCookieText] = useState("");

    useEffect(() => {

        const fetchCookie = async () => {

            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

            let raw = JSON.stringify({

                'company_name': "test"
            });

            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    fetchData,
                    requestOptions
                );
                const data = await response.text();
                setCookieText(data);

            } catch (error) {
                console.log(error);
            }
        }

        fetchCookie();

    }, [])

    //console.log(type)


    return (
        <>

            {
                type != undefined ?

                    <div className='cookies-container'>
                        <div className='cookies-bottom'>
                            {
                                cookieText ? <div dangerouslySetInnerHTML={{ __html: cookieText }} /> : ""
                            }
                        </div>
                    </div>

                    :

                    <div className='cookies-container'>
                        <div className='cookies-bottom'>
                            {/* <h1 className='cookies-heading'>Privacy & Cookie Policy</h1> */}
                            {
                                cookie == true ?
                                    <p className='cookies-starter'>This Policy applies as between you, the User of this Website and ”Loyalty Cover
                                        Software Limited” the owner and provider of this Website. This Policy applies to our
                                        use of any and all Data collected by us in relation to your use of the Website.</p>
                                    : ""
                            }

                            {props.map((item, propsIndex) => {
                                const { title, subtitle, content, description } = item;

                                return (
                                    <div key={propsIndex}>
                                        <h3 className='bolder-font'>{propsIndex + 1}.{title}</h3>
                                        <p>{subtitle}</p>
                                        {
                                            content.length !== 0 ? (
                                                content.map((contentItem, contentIndex) => {
                                                    const { subtitle, description } = contentItem;
                                                    return (
                                                        <span key={contentIndex}>
                                                            {
                                                                subtitle ? (
                                                                    <p><span className='bolder-font'>{subtitle} </span><span>{description}</span></p>
                                                                ) : (
                                                                    <p>
                                                                        <span className='bolder-font'>{propsIndex + 1}.{contentIndex + 1}.</span> <span>{description}</span></p>
                                                                )
                                                            }
                                                        </span>
                                                    )
                                                })
                                            ) : ''
                                        }

                                        <p>{description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            }
        </>
    )
}

export default Cookies